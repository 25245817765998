import React, { Component } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format';

import theme from '../../config/theme'
import translate from '../../lang'

const Row = styled.div`
  max-width: ${theme.container};
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const Col = styled.div`
  width: 100%;
  margin: 20px 0;

  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 50%;
  }
`

const ColOrder = styled(Col)`
  order: 1;

  @media (min-width: ${theme.breakpoints.tablet}) {
    order: 0;
  }
`

const ColSync = styled(Col)`
  order: 0;
  text-align: right;

  @media (min-width: ${theme.breakpoints.tablet}) {
    order: 1;
  }
`

const Title = styled.div`
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;

  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 95%;
  }

  h3 {
    display: inline-block;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: -1px;
    border-bottom: 3px solid #3e4143;
  }
`

const Address = styled.div`
  white-space: pre-wrap;
`

const ListGroup = styled.div`
  width: 100%;
`

const List = styled.ul`
  width: 100%;
  margin: 10px 0;
  list-style: none;
  font-size: 13px;
`

const Item = styled.li`
  float: left;

  &.name {
    width: 100%;

    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 40%;
    }
    
    &.charge {
      color: gray;
      
      @media (min-width: ${theme.breakpoints.desktop}) {
        padding-left: 25px;
      }
    }
  }
  &.sku {
    width: 50%;
    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 10%;
    }

    &.charge {
      color: gray;
    }
  }
  &.qty {
    width: 25%;
    text-align: right;
    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 6%;
    }
  }
  &.price {
    width: 25%;
    text-align: right;
    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 8%;
    }

    &.charge {
      color: gray;
    }
  }
  &.date {
    width: 28%;
    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 12%;
      text-align: center;
    }

    &.charge {
      display: none;
    }
  }
  &.status {
    width: 44%;
    text-align: center;
    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 12%;
    }

    &.charge {
      display: none;
    }
  }
`

const SpanStatus = styled.span`
  display: inline-block;
  width: 90%;
  margin-top: -5px;
  padding: 5px;
  border-radius: 4px;

  &.green {
    color: rgb(60, 118, 61);
    background-color: rgb(223, 240, 216);
  }

  &.orange {
    color: rgb(222, 143, 53);
    background-color: rgb(238, 221, 196);
  }
`

function shippingDate(val) {
  let year = new Intl.DateTimeFormat('es-ES', { year: 'numeric' }).format(new Date(val));

  if (parseInt(year) <= 1900) {
    return '';
  } else {
    return new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(val));
  }

}

class Response extends Component {

  newSearch() {
    document.getElementById('content').style.opacity = 0;
    setTimeout(function() {
      document.getElementById('status').style.display = 'none';
      document.getElementById('form').style.display = 'flex';
      document.getElementById('content').style.opacity = 1;
    }, 500);
  }

  render() {
    const state = this.props.state;
    const response = state.response;
    return (
      <div id="status">
        <Row>
          <ColOrder>
            <Title>
              <h3>{ translate('response', 'title') }</h3>
            </Title>
            <div>
              { state.type === 'online' && <><strong>{ translate('response', 'orderOnlineNr') }:</strong> { response.order_online }<br /></> }
              <strong>{ translate('response', 'orderStoreNr') }:</strong> { response.order_store }<br />
              <strong>{ translate('response', 'date') }:</strong> { response.date }<br />
              <strong>{ translate('response', 'amount') }:</strong> <NumberFormat value={ response.total_amount } displayType={ 'text' } thousandSeparator={ '.' } decimalSeparator={ ',' } decimalScale={ '2' } fixedDecimalScale={ 'true' } suffix={ ' €' } /><br />
              <strong>{ translate('response', 'pending') }:</strong> <NumberFormat value={ response.pending_amount } displayType={ 'text' } thousandSeparator={ '.' } decimalSeparator={ ',' } decimalScale={ '2' } fixedDecimalScale={ 'true' } suffix={ ' €' } /><br />
              <strong>{ translate('response', 'channel') }:</strong> { translate('stores', response.channel) }
            </div>
          </ColOrder>
          <ColSync>
            { translate('response', 'updated') }: { response.updated }
          </ColSync>
        </Row>
        <Row>
          <Col>
            <Title>
              <h3>{ translate('response', 'billingAddress') }</h3>
            </Title>
            <Address>
              { response.billing_address }
            </Address>
          </Col>
          <Col>
            <Title style={{ width: '100%' }}>
              <h3>{ translate('response', 'shippingAddress') }</h3>
            </Title>
            <Address>
              { response.shipping_address }
            </Address>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Title style={{ width: '100%' }}>
            <h3>{ translate('response', 'details') }</h3>
          </Title>
        </Row>
        <Row>
          <List style={{ borderBottom: '1px dotted #dcdcdc' }}>
            <Item className="name"><strong>{ translate('response', 'colProduct') }</strong></Item>
            <Item className="sku"><strong>{ translate('response', 'colSku') }</strong></Item>
            <Item className="qty"><strong>{ translate('response', 'colQty') }</strong></Item>
            <Item className="price"><strong>{ translate('response', 'colPrice') }</strong></Item>
            <Item className="date"><strong>{ translate('response', 'colDateRequest') }</strong></Item>
            <Item className="date"><strong>{ translate('response', 'colDateConfirmed') }</strong></Item>
            <Item className="status"><strong>{ translate('response', 'colStatus') }</strong></Item>
          </List>
        </Row>
        <Row>
          {response.products.map((product, index) =>
            <ListGroup>
              <List key={ index }>
                <Item className="name">{ product.name }</Item>
                <Item className="sku">{ product.sku }</Item>
                <Item className="qty"><NumberFormat value={ product.qty } displayType={ 'text' } thousandSeparator={ '.' } decimalSeparator={ ',' } /> { translate('response', 'units') }</Item>
                <Item className="price"><NumberFormat value={ product.amount } displayType={ 'text' } thousandSeparator={ '.' } decimalSeparator={ ',' } decimalScale={ '2' } fixedDecimalScale={ 'true' } suffix={ ' €' } /></Item>
                <Item className="date">{ product.date_requested }</Item>
                <Item className="date">{ product.date_confirmed }</Item>
                <Item className="status"><SpanStatus className={ product.color }>{ translate('status', product.status) }</SpanStatus></Item>
              </List>
              {product.services.map((service, i) =>
                <List key={ i }>
                  <Item className="name charge">{ service.name }</Item>
                  <Item className="sku charge">{ service.code }</Item>
                  <Item className="qty charge">&nbsp;</Item>
                  <Item className="price charge"><NumberFormat value={ service.amount } displayType={ 'text' } thousandSeparator={ '.' } decimalSeparator={ ',' } decimalScale={ '2' } fixedDecimalScale={ 'true' } suffix={ ' €' } /></Item>
                  <Item className="date charge">&nbsp;</Item>
                  <Item className="date charge">&nbsp;</Item>
                  <Item className="status charge">&nbsp;</Item>
                </List>
              )}
            </ListGroup>
          )}
        </Row>
        <Row style={{ margin: '20px auto' }}>
          <Col style={{ width: '100%', textAlign: 'center' }}>
            <button className="btn" onClick={ this.newSearch }>{ translate('response', 'searchAgain') }</button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Response