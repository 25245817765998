import React from 'react'
import styled from 'styled-components'

import theme from '../../config/theme'
import translate from '../../lang'
import Image from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

const Footer = styled.footer`
  width: 100vw;
  max-width: 100%;
`

const FooterContainer = styled.div`
  max-width: ${theme.container};
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const ImageContainer = styled.div`
  width: 100%;
`

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  border-top: 1px solid #ccc;

  @media (min-width: ${theme.breakpoints.desktop}) {
    justify-content: flex-start;
  }
`

const Item = styled.li`
  width: calc(50% - 20px);
  color: #8a8a8a;
  margin: 5px 10px;
  font-size: 12px;
  text-align: center;

  @media (min-width: ${theme.breakpoints.tablet}) {
    width: auto;
    margin: 0 25px 0 10px;
    text-align: left;
  }
`

const ExternalLink = styled.a`
  color: #8a8a8a;

  :hover {
    color: #8a8a8a;
  }
  
  @media (min-width: ${theme.breakpoints.desktop}) {
    text-decoration: underline;
  }
`

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          footer: file(relativePath: { eq: "footer.png" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <Footer>
            <FooterContainer>
              <ImageContainer>
                <Image fluid={data.footer.childImageSharp.fluid} style={{ maxWidth: '600px', margin: 'auto' }} />
              </ImageContainer>
              <List>
                  <Item>© Conforama</Item>
                  { process.env.LANG === 'es' && <>
                    <Item><ExternalLink href="https://www.conforama.es/preguntas-frecuentes" target="_blank" rel="noreferrer">{ translate('footer', 'faq') }</ExternalLink></Item>
                    <Item><ExternalLink href="https://www.conforama.es/terminos-y-condiciones" target="_blank" rel="noreferrer">{ translate('footer', 'terms') }</ExternalLink></Item>
                    <Item><ExternalLink href="https://www.conforama.es/politica-de-privacidad" target="_blank" rel="noreferrer">{ translate('footer', 'policy') }</ExternalLink></Item>
                  </> }
                  { process.env.LANG === 'pt' && <>
                    <Item><ExternalLink href="https://www.conforama.pt/perguntas-frequentes" target="_blank" rel="noreferrer">{ translate('footer', 'faq') }</ExternalLink></Item>
                    <Item><ExternalLink href="https://www.conforama.pt/termos-e-condicoes" target="_blank" rel="noreferrer">{ translate('footer', 'terms') }</ExternalLink></Item>
                    <Item><ExternalLink href="https://www.conforama.pt/politica-de-privacidade" target="_blank" rel="noreferrer">{ translate('footer', 'policy') }</ExternalLink></Item>
                  </> }
              </List>
            </FooterContainer>
        </Footer>
      )}
    />
  )
}
