import es from "./es"
import pt from "./pt"

const lang = ( process.env.LANG === 'es' ) ? es : pt ;

const translate = (area, text) => {
    return lang[area][text] ? lang[area][text] : text;
}

export default translate
