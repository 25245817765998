const theme = {
  colors: {
    primary: '#e20613',
    background: '#fff',
    text: '#444',
  },
  container: '1200px',
  breakpoints: {
    mobile: '425px',
    tablet: '768px',
    desktop: '1024px',
  },
}

export default theme