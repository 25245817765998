import React, { Component } from 'react'
import styled from 'styled-components'

import theme from '../../config/theme'

const SpanError = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${theme.colors.primary};
`

class Error extends Component {

  render() {
    const error = this.props.error;
    console.log(error);
    return (
      <SpanError>{error}</SpanError>
    )
  }
}

export default Error