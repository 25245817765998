const es = {
    index: {
        title : 'Estado de mi pedido',
        subtitle : 'Por favor, introduce los siguientes datos de tu compra',
        moreInfo : 'Para cualquier consulta relacionada con la fecha de entrega clica',
        moreInfoUrl : 'https://conforama.exevi.com/soporte/es/',
        moreInfoClick : 'aquí',
    },
    form: {
        orderOnline : 'Tengo un pedido web',
        orderOffline : 'Tengo un pedido de tienda',
        help: 'Ayuda',
        store : 'Tienda',
        orderNr : 'Nº Pedido',
        orderEx : 'Ej: EPVT0800633',
        date : 'Fecha',
        amount : 'Importe total',
        zip : 'Código postal Dirección de facturación',
        zipEx : 'Ej: 08006',
        zipPattern: '[0-9]{5}',
        zipTooltip: 'Para pedidos con recogida en tienda, intoduzca el código postal de la tienda de recogida que se encuentra en su ticket de caja o en la confirmación de su pedido Online',
        search : 'Consultar estado',
        captchaError: 'Pregunta de seguridad obligatoria',
        statusSearching : 'Buscando...',
        statusNotFound : 'Lo sentimos, no hemos encontrado ningún pedido con estos datos',
        statusError : 'Lo sentimos, en estos momentos no podemos comprobar el estado del pedido',
    },
    response: {
        title : 'Datos del pedido',
        orderOnlineNr : 'Nº Pedido online',
        orderStoreNr : 'Nº Pedido',
        date: 'Fecha',
        amount : 'Importe total',
        pending : 'Importe pendiente',
        channel : 'Canal de venta',
        updated : 'Última actualización',
        billingAddress : 'Dirección de facturación',
        shippingAddress: 'Dirección de entrega',
        details : 'Detalles del pedido',
        colProduct : 'Producto',
        colSku : 'Código',
        colQty : 'Cantidad',
        colPrice: 'Precio',
        colDateRequest : 'Fecha solicitada',
        colDateConfirmed : 'Fecha confirmada',
        colStatus : 'Estado',
        units : 'ud/s',
        searchAgain : 'Realizar otra búsqueda',
    },
    footer: {
        faq : 'Preguntas frecuentes',
        terms : 'Términos y Condiciones',
        policy : 'Política de privacidad',
    },
    stores: {
        601 : 'SS.RR.',
        602 : 'BURJASSOT',
        603 : 'BADALONA',
        604 : 'ALFAFAR',
        608 : 'ALICANTE',
        609 : 'PALMA',
        610 : 'GRANADA',
        611 : 'ALCORCON',
        612 : 'PAMPLONA',
        613 : 'OVIEDO',
        614 : 'SEVILLA',
        616 : 'VIANA (LOGROÑO)',
        617 : 'BARAKALDO',
        618 : 'MALAGA',
        647 : 'RIVAS',
        648 : 'OROTAVA',
        649 : 'VILANOVA',
        680 : 'ZARAGOZA',
        686 : 'A CORUÑA',
        688 : 'GETAFE', 
        689 : 'SABADELL',
        690 : 'TENERIFE',
        691 : 'CHAFIRAS',
        693 : 'ZENIA',
        694 : 'ALCALA',
        696 : 'ONLINE CONFORAMA',
        698 : 'SANT BOI DE LLOBREGAT',
        901 : 'ARINAGA',
        902 : 'LANZAROTE',
        903 : 'FINESTRAT',
        904 : 'GUADAIRA',
        905 : 'TAMARACEITE',
        917 : 'CONDOMINA-MURCIA',
        918 : 'CORDOBA',
        919 : 'SANTANDER',
        920 : 'CASTELLON',
        921 : 'SAGUNTO',
        922 : 'LOS BARRIOS',
        923 : 'VITORIA',
        924 : 'SALT',
        925 : 'MAJADAHONDA',
        926 : 'TERRASSA',
        927 : 'HUERCAL',
        928 : 'LLEIDA',
        929 : 'LEON',
    },
    status: {
        'Confirmed order' : 'Pedido confirmado',
        'Order available for pickup' : 'Pedido disponible para recogida',
        'Shipment in preparation' : 'Envío en preparación',
        'Ongoing delivery' : 'Reparto en Curso',
        'Package delivered to the logistics operator' : 'Paquete entregado al operador Logístico',
        'Delivered' : 'Entregado',
        'Return made' : 'Devolución realizada',
        'Expected pickup for the "date"' : 'Recogida prevista para la fecha',
        'Goods collected' : 'Mercancía recogida',
        'Return completed' : 'Devolución completada',
        'Payment not received by the stipulated deadline' : 'Pago no recibido en el plazo estipulado',
        'Pending payment' : 'Pendiente de pago',
    }
  }
  
  export default es