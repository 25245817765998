import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Response, Error } from './'
import theme from '../../config/theme'
import translate from '../../lang'

import ReCAPTCHA from "react-google-recaptcha";

const apiUrl = process.env.API;

const Row = styled.div`
  max-width: ${theme.container};
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const Col = styled.div`
  width: 100%;
  margin: 20px 0;

  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 50%;
  }
`

const OrderForm = styled.form`
  width: 300px;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 332px;
  }
`

const Field = styled.div`
  margin-bottom: 25px;

  label {
    font-weight: bold;
  }

  input, select {
    width: 100%;
    margin-top: 5px;
    padding: 4px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #555;
    font-weight: normal;
    font-style: italic;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    input, select {
      width: 91%;
    }
  }
`

const Tooltip = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 9%;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    img {
      height: 20px;
      margin-bottom: -5px;
    }
    span {
      visibility: hidden;
      position: absolute;
      z-index: 99;
      background: #fff;
      border: 1px solid ${theme.colors.primary};
      padding: 10px;
      margin-top: -10px;
      margin-left: 10px;
      border-radius: 10px;
      font-weight: normal;
      max-width: 25%;
    }

    :hover span {
      visibility: visible;
    }
  }
`

const recaptchaRef = React.createRef();

class Form extends Component {
    state = {
      type: '',
      store: '',
      order: '',
      date: '',
      amount: '',
      zip: '',
      response: '',
      safariDesktop: false,
      captchaFailed: false,
    }

    handleInputChange = event => {
      let target = event.target
      let name = target.name
      let value = target.value

      this.setState({
        [name]: value,
      })
    }

    handleCaptchaChange = () => {
      if (!recaptchaRef.current.getValue()) {
        this.setState({captchaFailed: true})
        this.setState({error: translate('form', 'captchaError')});
      }
    }
  
    handleSubmit = event => {
      event.preventDefault();
      
      if (!recaptchaRef.current.getValue()) {
        this.setState({captchaFailed: true})
        this.setState({error: translate('form', 'captchaError')});
        return;
      }

      let url = new URL(apiUrl)
      url.search = new URLSearchParams({
        order: this.state.order,
        zip: this.state.zip
      });

      this.setState({error: translate('form', 'statusSearching')});
//      fetch('test/api.json', {
//      fetch('test/error.json', {
      fetch(url.href, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache'})
      .then(response => response.json())
      .then(json => {
        if (JSON.stringify(json) !== '[]') {
          document.getElementById('content').style.opacity = 0;
          document.getElementById('error').style.opacity = 0;
          setTimeout(function() {
            this.setState({response: json});
            document.getElementById('form').style.display = 'none';
            document.getElementById('status').style.display = 'block';
            document.getElementById('content').style.opacity = 1;
          }.bind(this), 500);
        } else {
          document.getElementById('error').style.opacity = 0;
          setTimeout(function() {
            document.getElementById('error').style.opacity = 1;
            this.setState({error: translate('form', 'statusNotFound')});
          }.bind(this), 500);
        }
      })
      .catch(error => {
        document.getElementById('error').style.opacity = 0;
        setTimeout(function() {
          document.getElementById('error').style.opacity = 1;
          this.setState({error: translate('form', 'statusError')});
        }.bind(this), 500);
      });
    }
  
    safariDesktop() {
      if (/Safari/i.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor) && !/Mobi|Android/i.test(navigator.userAgent)) {
        let version = navigator.userAgent.match(/(\d+)\.(\d+) Safari/i);
        if (version[1] <= 14 && version[2] < 1) {
          this.setState({ safariDesktop: true });
          return true;
        }
      }
      return false;
    }
    
    componentDidMount() {
      let safariDesktop = this.safariDesktop();

      if (window.location.search) {
        let urlParams = new URLSearchParams(window.location.search);
        let urlOrder = urlParams.get('order');
        let urlZip = urlParams.get('zip');

//        this.setState({ order: urlOrder })
//        this.setState({ zip: urlZip })

      }
    }
  
    render() {
      const data = this.props.data;
      return (
        <>
          <Row id="form">
            <Col>
              <OrderForm onSubmit={this.handleSubmit}>
                <Field>
                  <label htmlFor="order">
                    { translate('form', 'orderNr') }
                    <input type="text" name="order" id="order" value={this.state.order} placeholder={ translate('form', 'orderEx') } required onChange={this.handleInputChange} pattern="[a-zA-Z0-9-]+" />
                    <Tooltip style={{ visibility: 'hidden' }}>
                      <img src={data.info.publicURL} alt={ translate('form', 'help') } />
                      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id commodo mi, ac porta neque. Aliquam porttitor, ligula at laoreet bibendum, nunc tortor consequat dolor, a pretium eros nulla id elit.</span>
                    </Tooltip>
                  </label>
                </Field>
                <Field>
                  <label htmlFor="zip">
                    { translate('form', 'zip') }
                    <input type="text" name="zip" id="zip" value={this.state.zip} placeholder={ translate('form', 'zipEx') } required onChange={this.handleInputChange} pattern={ translate('form', 'zipPattern') } />
                    <Tooltip>
                      <img src={data.info.publicURL} alt={ translate('form', 'help') } />
                      <span>{ translate('form', 'zipTooltip') }</span>
                    </Tooltip>
                  </label>
                </Field>
                <Field>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lecrm4eAAAAAN8nfRiZL0mvtNdteQGia_EKRau7"
                    onChange={this.handleCaptchaChange}
                    onError={this.handleCaptchaChange}
                  />
                </Field>
                <button id="submit" type="submit" className="btn">{ translate('form', 'search') }</button>
              </OrderForm>
              <div id="error" style={{ marginTop: '30px', marginRight: '30px', transition: 'all 0.5s ease-out' }}>{ this.state.error && <Error error={ this.state.error }/> }</div>
            </Col>
            <Col id="banner" style={{ textAlign: 'center' }}>
              <Image fluid={data.banner.childImageSharp.fluid} style={{ maxWidth: '500px', margin: 'auto' }}/>
            </Col>
          </Row>
          { this.state.response && <Response state={ this.state }/> }
        </>
      )
    }
}

export default () => {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
            banner: file(relativePath: { eq: "banner.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            info: file(relativePath: { eq: "info-circle-solid.svg" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <Form data={data} />
        )}
      />
    )
}