import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import theme from '../../config/theme'
import translate from "../../lang"

const Header = styled.header`
  width: 100vw;
  max-width: 100%;
`

const HeaderContainer = styled.div`
  max-width: ${theme.container};
  margin-left: auto;
  margin-right: auto;
  padding: 20px 10px 10px 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (min-width: ${theme.breakpoints.desktop}) {

  }
`

const LogoConforama = styled.img`
  width: 275px;
  margin: auto;

  @media (min-width: ${theme.breakpoints.tablet}) {
    margin: 0;
  }
`

const LogoMyconfo = styled.img`
  width: 185px;
  margin: 0;
  display: none;

  @media (min-width: ${theme.breakpoints.tablet}) {
    display: inline-block;
  }
`

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          logoConforama: file(relativePath: { eq: "logo-conforama.svg" }) {
            publicURL
          }
          logoMyconfo: file(relativePath: { eq: "logo-myconfo.svg" }) {
            publicURL
          }
        }
      `}
      render={data => (
        <Header>
          <HeaderContainer>
            { process.env.LANG === 'es' && <>
              <a href="https://www.conforama.es" target="_blank" rel="noreferrer">
                <LogoConforama src={data.logoConforama.publicURL} title={data.site.siteMetadata.title} alt={data.site.siteMetadata.title} />
              </a>
            </> }
            { process.env.LANG === 'pt' && <>
              <a href="https://www.conforama.pt" target="_blank" rel="noreferrer">
                <LogoConforama src={data.logoConforama.publicURL} title={data.site.siteMetadata.title} alt={data.site.siteMetadata.title} />
              </a>
            </> }
            <LogoMyconfo src={data.logoMyconfo.publicURL} title={data.site.siteMetadata.title} alt={data.site.siteMetadata.title} />
          </HeaderContainer>
        </Header>
      )}
    />
  )
}
