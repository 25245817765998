import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'

import { Header, Footer } from '../components'
import theme from '../../config/theme'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  a {
    color: ${theme.colors.text};
    text-decoration: none;
    :hover {
      color: ${theme.colors.primary};
    }
  }

  .btn {
    width: 300px;
    padding: 10px;
    transition: all 0.3s linear;

    background-color: ${theme.colors.primary};
    color: #fff;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;

    :hover {
      color: #ffffff;
      background-color: ${theme.colors.primary};
    }

    :focus {
      outline: none;
    }

    :disabled {
      cursor: no-drop;
      color: #ccc;
    }
  }

`

const Layout = styled.div`

`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ({ children }) => (
  <Layout>
    <GlobalStyle />
    <Header />
    {children}
    <Footer />
  </Layout>
)